/* eslint-disable no-undef */
import { upsellList, upsellListTEST } from './gala-upsell-data.js'

console.log('gala upsells v2.7')

// WINDOW INFORMATION
const pageURL = window.location.href.toLowerCase()

// IS TEST?
const isTestTNEW = (pageURL.includes('tnhs.cloud'))

/**
 * ON FOR ALL SALES?
 * Enables upsell for all sales not just gala.
 * Mark as false to only enable for the gala upsell.
 */
const isOnForAllSales = false

// GET TNEW SESSION KEY
const session = dataLayer.find(function (e) {
  return e.hasOwnProperty('session')
}).session
const tnewCookie = session.key

// SET XML REQUEST
const xhttp = new XMLHttpRequest()

/**
 * SHOW USER MESSAGE
 * Triggers a message above the basket.
 */
function showMessage (message) {
  document.getElementById('tn-alert-message-template').innerHTML = '<div id="update-seats-alert"><p>' + message + '</p></div>'
  document.getElementById('tn-page-heading').scrollIntoView()
}

/**
 * DONATIONS BASED REQUEST
 * When upselling a donation
 */
function upsellDonation (fundId, contributionId, amount) {
  const donationsData = new FormData()
  donationsData.append('customData[fundId]', fundId)
  donationsData.append('customData[contributionId]', contributionId)
  donationsData.append('customData[amount]', amount)
  donationsData.append('encryptedKey', tnewCookie)
  donationsData.append('site', (isTestTNEW ? 'test' : 'live'))
  xhttp.onreadystatechange = function () {
    if (this.readyState === 4) {
      if (this.status === 200) {
        console.log(this.responseText)
        location.reload()
      } else {
        // On error remove the upsell box and display message
        document.getElementsByClassName('c-cross-sell')[0].remove()
        showMessage('There was a problem adding this to your cart. Please reload the page and try again or contact the box office.')
      }
    }
  }
  xhttp.open('POST', 'https://secure.theconrad.org/api/upsell-donations.php', true)
  xhttp.send(donationsData)
}

/**
 * Add an upsell message including a dropdown of options
 * @param upsells
 */
function addUpsell (upsells) {
  let messageHtml = '<div class="c-cross-sell upsellDonations">' +
    '<div class="c-cross-sell__message">' +
    '<h3 class="c-cross-sell__title">Underwriting Opportunities</h3>' +
    '<p>Underwriting gifts are fully deductible and include sponsor recognition throughout the event. Supporters at $2,500 per person or $5,000 or more per household will also be invited to a private Sponsor Party.</p>' +
    '</div>' +
    '<div class="c-cross-sell__cta">' +
    '<select class="amountDropdown">'
  upsells.forEach(function (item) {
    messageHtml += '<option value="' + item.fundId + '_' + item.contributionId + '_' + item.amount + '">' + item.label + '</option>'
  })
  messageHtml += '</select>' +
    '<a class="btn btn-primary btn-block submit">Add to cart</a>' +
    '</div>' +
    '</div>'
  document.getElementById('tn-page-heading').insertAdjacentHTML('afterend', messageHtml)
}

// ADD LOADER AND DISABLE BUTTON
function setButtonLoading (element) {
  element.insertAdjacentHTML('beforeend', '<span class="tn-waiting"></span>')
  element.setAttribute('disabled', 'disabled')
}

if (pageURL.includes('cart/details')) {
  // GET BASKET CONTENTS
  const products = dataLayer.find(function (e) {
    return e.hasOwnProperty('transactionProducts')
  }).transactionProducts
  // CHECK GALA PRODUCTS and exclude any exception events
  const exceptions = ['1532|Opera Neo Aria Gala']
  const galaProducts = products.filter(product => product.name.includes('Gala') && !exceptions.includes(product.name))
  const includesGalaProducts = (galaProducts.length > 0)
  /**
   * IF UPSELL ALREADY IN BASKET
   * Override includes gala products to force the upsell to disappear
   */
  let upsellInBasket = false
  products.forEach(function (product) {
    const productSKUParts = product.sku.split('|')
    const upsellChecklist = (isTestTNEW) ? upsellListTEST : upsellList
    const upsellSKUInBasket = upsellChecklist.filter(upsell => upsell.contributionId === parseInt(productSKUParts[0], 10))
    if (upsellSKUInBasket.length > 0) {
      // upsellInBasket = true // Always on
    }
  })
  /**
   * DISPLAY UPSELL
   * Includes gala products, or if the global isOnForAllSales set to true
   * and no upsell already in the basket.
   */
  if ((includesGalaProducts || isOnForAllSales) && !upsellInBasket) {
    if (isTestTNEW) {
      addUpsell(upsellListTEST)
    } else {
      addUpsell(upsellList)
    }
    const upsellDonationMessages = Array.from(document.getElementsByClassName('upsellDonations'))
    upsellDonationMessages.forEach(function (item) {
      item.querySelector('.submit').addEventListener('click', (button) => {
        setButtonLoading(button.target)
        let dropdownValues = item.querySelector('.amountDropdown').value.split('_')
        if (dropdownValues) {
          upsellDonation(dropdownValues[0], dropdownValues[1], dropdownValues[2])
        }
      })
    })
  }
}
