export let upsellList = [
  {
    fundId: 231,
    contributionId: 148,
    amount: 50000,
    label: '$50,000 Underwriting'
  },
  {
    fundId: 232,
    contributionId: 157,
    amount: 30000,
    label: '$30,000 Underwriting'
  },
  {
    fundId: 233,
    contributionId: 158,
    amount: 25000,
    label: '$25,000 Underwriting'
  },
  {
    fundId: 234,
    contributionId: 159,
    amount: 15000,
    label: '$15,000 Underwriting'
  },
  {
    fundId: 235,
    contributionId: 160,
    amount: 10000,
    label: '$10,000 Underwriting'
  },
  {
    fundId: 236,
    contributionId: 161,
    amount: 7500,
    label: '$7,500 Underwriting'
  },
  {
    fundId: 237,
    contributionId: 162,
    amount: 5000,
    label: '$5,000 Underwriting'
  },
  {
    fundId: 238,
    contributionId: 163,
    amount: 3000,
    label: '$3,000 Underwriting'
  },
  {
    fundId: 239,
    contributionId: 164,
    amount: 2500,
    label: '$2,500 Underwriting'
  },
  {
    fundId: 240,
    contributionId: 165,
    amount: 2000,
    label: '$2,000 Underwriting'
  },
  {
    fundId: 241,
    contributionId: 166,
    amount: 1000,
    label: '$1,000 Underwriting'
  },
  {
    fundId: 242,
    contributionId: 167,
    amount: 500,
    label: '$500 Underwriting'
  }
]

export let upsellListTEST = [
  {
    fundId: 231,
    contributionId: 148,
    amount: 50000,
    label: '$50,000 Underwriting'
  },
  {
    fundId: 232,
    contributionId: 157,
    amount: 30000,
    label: '$30,000 Underwriting'
  },
  {
    fundId: 233,
    contributionId: 158,
    amount: 25000,
    label: '$25,000 Underwriting'
  },
  {
    fundId: 234,
    contributionId: 159,
    amount: 15000,
    label: '$15,000 Underwriting'
  },
  {
    fundId: 235,
    contributionId: 160,
    amount: 10000,
    label: '$10,000 Underwriting'
  },
  {
    fundId: 236,
    contributionId: 161,
    amount: 7500,
    label: '$7,500 Underwriting'
  },
  {
    fundId: 237,
    contributionId: 162,
    amount: 5000,
    label: '$5,000 Underwriting'
  },
  {
    fundId: 238,
    contributionId: 163,
    amount: 3000,
    label: '$3,000 Underwriting'
  },
  {
    fundId: 239,
    contributionId: 164,
    amount: 2500,
    label: '$2,500 Underwriting'
  },
  {
    fundId: 240,
    contributionId: 165,
    amount: 2000,
    label: '$2,000 Underwriting'
  },
  {
    fundId: 241,
    contributionId: 166,
    amount: 1000,
    label: '$1,000 Underwriting'
  },
  {
    fundId: 242,
    contributionId: 167,
    amount: 500,
    label: '$500 Underwriting'
  }
]
