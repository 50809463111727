require('what-input')
require('./gala-upsells.js')
require('./confirmation-page.js')

$(() => {
  //
  // Header Menu
  //
  const $body = $('body')
  const menuToggle = $('#c-menu-trigger')
  const menuLabel = $('#c-menu-trigger__label')
  let mainMenuShown = false

  menuToggle.click(function (e) {
    e.preventDefault()
    if (mainMenuShown) {
      closeMainMenu()
    } else {
      openMainMenu()
    }
    mainMenuShown = !mainMenuShown
  })

  function openMainMenu () {
    $body.toggleClass('state--nav-open state--nav-closed')
    menuToggle.attr('aria-expanded', 'true')
    menuLabel.text('close')
  }

  function closeMainMenu () {
    $body.toggleClass('state--nav-open state--nav-closed')
    menuToggle.attr('aria-expanded', 'false')
    menuLabel.text('menu')
  }

  // Esc closes header menu

  const KEYCODE_ESC = 27
  $(document).keyup(function (e) {
    if (e.keyCode === KEYCODE_ESC) {
      if (mainMenuShown) {
        closeMainMenu()
        mainMenuShown = !mainMenuShown
      }
    }
  })
})
