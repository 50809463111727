if (document.querySelector('main.tn-receipt-page')) {
  let addedCalendarButton = false
  const performances = document.querySelectorAll('.tn-cart__section--performances .tn-cart__section-items .tn-cart-item-summary')

  if (performances.length) {
    performances.forEach((performance) => {
      const eventTitle = performance.querySelector('.tn-performance-title').innerText
      const eventLocation = performance.querySelector('.tn-cart-item-summary__property--location').innerText
      const dateTimeText = performance.querySelector('.tn-cart-item-summary__property--date-time').innerText
      const eventDateTime = toDateTimeObject(dateTimeText)
      console.log(eventDateTime, eventTitle)
      if (eventDateTime.date && eventDateTime.startTime && eventTitle.length) {
        addedCalendarButton = true
        addToCalendarCTA(performance, eventDateTime, eventTitle, eventLocation)
      }
    })
  }
  if (addedCalendarButton) {
    const script = document.createElement('script')
    script.src = 'https://cdn.jsdelivr.net/npm/add-to-calendar-button@2'
    document.head.appendChild(script)
  }
}

/**
   * Converts the date string into an object containing date (in ISO format),
   * startTime and endTime
   * @param {string} string
   * @returns Object
   */
function toDateTimeObject (text) {
  const plainDateTime = text.replace(/\n|Date\/Time:/g, '')
    .trim()
    .replace(/[AP]/, ' $&') // Adds space after AM/PM

  const dateObject = new Date(plainDateTime)
  const timezoneInsensitiveDateObject = new Date(dateObject.getTime() - (dateObject.getTimezoneOffset() * 60000))

  if (dateObject !== 'Invalid Date' && timezoneInsensitiveDateObject !== 'Invalid Date') {
    // And end date is required but we don't know the duration, so go with 3 hours
    const endTime = new Date(dateObject.getTime() + 3 * 3600 * 1000)
    return {
      date: timezoneInsensitiveDateObject.toISOString().slice(0, 10),
      startTime: dateObject.toLocaleTimeString(),
      endTime: endTime.toLocaleTimeString()
    }
  }

  return {}
}

/**
 * Append the add-to-calendar-button to the target element
 * @param {element} target 
 * @param {object} dateTime 
 * @param {string} title 
 * @param {string} location 
 */
function addToCalendarCTA (target, dateTime, title, location) {
  let listNode = document.createElement('li')
  listNode.className = 'tn-cart-item-summary__property'
  listNode.innerHTML = `<add-to-calendar-button
        name="${title}"
        location="${location}"
        startDate="${dateTime.date}"
        startTime="${dateTime.startTime}"
        endTime="${dateTime.endTime}"
        timeZone="America/Los_Angeles"
        listStyle="dropdown-static"
        trigger="click"
        options="'Apple','Google','iCal','Outlook.com','Yahoo','Microsoft365'"
    ></add-to-calendar-button>`

  target.append(listNode)
}
