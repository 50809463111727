import { seatMapObject } from './viewFromSeatObject.js'
import './venueImages.js'
require('./fresco')

// Define S3 location
const assetUrl = 'https://la-jolla-music-society-assets.s3.us-east-1.amazonaws.com/tnew-template/qa/img/'

$(() => {
  // The venue needs to be defined before to add a class that the VFS function hooks onto
  if ($(".tn-event-detail__location:contains('The Baker-Baum Concert Hall')").length > 0) {
    $('body').addClass('state--venue-baker-baum')
  }
})

//
// Add View from seat functionality
//
const imgSmallUrl = `${assetUrl}Baker-Baum_small/`
const imgLargeUrl = `${assetUrl}Baker-Baum_large/`

// Options for the observer (which mutations to observe)
const config = { attributes: true, childList: true, subtree: false }

// Callback function to execute when mutations are observed
const callback = function (mutationsList, observer) {
  // Use traditional 'for loops' for IE 11
  for (const mutation of mutationsList) {
    if (mutation.type === 'childList') {
      addImgtoNode()
    }
  }
}

function addImgtoNode () {
  // Get details from pop-up once seat is clicked
  const seatZone = document.querySelector('.tn-syos-seat-info__header td.tn-syos-seat-info__seat-description')
  const seatRow = document.querySelector('.tn-syos-seat-info__header td.tn-syos-seat-info__seat-row')
  const seatNumber = document.querySelector('.tn-syos-seat-info__header td.tn-syos-seat-info__seat-number')

  // Query the seatmap Object and return a target seat
  const seatToFind = `${seatZone.innerText.split(' ')[0]}_${seatRow.innerText}_${seatNumber.innerText}`
  const obj = seatMapObject.find(o => o[seatToFind])
  const targetSeat = obj[seatToFind].targetSeat

  // Split up the target seat string so it can be used in captions and alt text
  const targetSeatString = targetSeat.split('_')
  const targetSeatZone = targetSeatString[0]
  const targetSeatRow = targetSeatString[1]
  const targetSeatNumber = targetSeatString[2]

  // Append an image to the seatmap
  const vfsImage = document.createElement('div')
  vfsImage.classList.add('vfs__image-container')
  const vfsImageTarget = document.querySelector('.tn-syos-seat-info__header.tn-syos-seat-info__header--with-close-button')
  vfsImageTarget.appendChild(vfsImage)
  if (window.innerWidth >= 768) {
    // Show fresco link if the screen is tablet or above
    vfsImage.innerHTML = `
    <a href="${imgLargeUrl}${targetSeat}_900.jpg"
    class="fresco vfs__link"
    data-fresco-caption="View of stage from seat number ${targetSeatRow}${targetSeatNumber} in ${targetSeatZone} section">
        <img
        src="${imgSmallUrl}${targetSeat}_270.jpg"
        alt="View of stage from seat number ${targetSeatRow}${targetSeatNumber} in ${targetSeatZone} section"
        height="180"
        width="270"
        class="vfs__seat-img"
        >
    <span class="vfs__expand">Expand Image<i class="fas fa-search"></i></span>
    </a>`
  } else {
    // No fresco on mobile because it'll interfere with full screen TNEW
    vfsImage.innerHTML = `
    <div class="vfs__link">
        <img
        src="${imgSmallUrl}${targetSeat}_270.jpg"
        alt="View of stage from seat number ${targetSeatRow}${targetSeatNumber} in ${targetSeatZone} section"
        height="180"
        width="270"
        class="vfs__seat-img"
        >
    </div>
    `
  }
}

function addObserverIfDesiredNodeAvailable () {
// Check if the price container exists within the Baker Baum SYOS seatmap
  const targetNode = document.querySelector('body.state--venue-baker-baum .tn-syos-price-type-selector__content-container')
  if (!targetNode) {
    // The node we need does not exist yet.
    // Wait 500ms and try again
    window.setTimeout(addObserverIfDesiredNodeAvailable, 500)
    return
  }
  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback)

  // Start observing the target node for configured mutations
  observer.observe(targetNode, config)
}
addObserverIfDesiredNodeAvailable()
