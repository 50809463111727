/* eslint-disable no-unused-vars */
const seatMapObject = [
/* Terrace Right */
  { 'Terrace_S_2': {
    'targetSeat': 'Terrace_S_4' }
  },
  { 'Terrace_S_4': {
    'targetSeat': 'Terrace_S_4' }
  },
  { 'Terrace_S_6': {
    'targetSeat': 'Terrace_S_4' }
  },
  { 'Terrace_S_8': {
    'targetSeat': 'Terrace_S_4' }
  },
  { 'Terrace_S_10': {
    'targetSeat': 'Terrace_S_4' }
  },
  { 'Terrace_S_12': {
    'targetSeat': 'Terrace_S_20' }
  },
  { 'Terrace_S_14': {
    'targetSeat': 'Terrace_S_20' }
  },
  { 'Terrace_S_16': {
    'targetSeat': 'Terrace_S_20' }
  },
  { 'Terrace_S_18': {
    'targetSeat': 'Terrace_S_20' }
  },
  { 'Terrace_S_20': {
    'targetSeat': 'Terrace_S_20' }
  },
  { 'Terrace_S_22': {
    'targetSeat': 'Terrace_S_20' }
  },
  { 'Terrace_S_24': {
    'targetSeat': 'Terrace_S_20' }
  },
  { 'Terrace_S_26': {
    'targetSeat': 'Terrace_S_20' }
  },
  { 'Terrace_S_28': {
    'targetSeat': 'Terrace_S_34' }
  },
  { 'Terrace_S_30': {
    'targetSeat': 'Terrace_S_34' }
  },
  { 'Terrace_S_32': {
    'targetSeat': 'Terrace_S_34' }
  },
  { 'Terrace_S_34': {
    'targetSeat': 'Terrace_S_34' }
  },
  { 'Terrace_S_34': {
    'targetSeat': 'Terrace_S_34' }
  },
  /* Terrace Left */
  { 'Terrace_S_1': {
    'targetSeat': 'Terrace_S_1' }
  },
  { 'Terrace_S_3': {
    'targetSeat': 'Terrace_S_1' }
  },
  { 'Terrace_S_5': {
    'targetSeat': 'Terrace_S_1' }
  },
  { 'Terrace_S_7': {
    'targetSeat': 'Terrace_S_1' }
  },
  { 'Terrace_S_9': {
    'targetSeat': 'Terrace_S_1' }
  },
  { 'Terrace_S_11': {
    'targetSeat': 'Terrace_S_17' }
  },
  { 'Terrace_S_13': {
    'targetSeat': 'Terrace_S_17' }
  },
  { 'Terrace_S_15': {
    'targetSeat': 'Terrace_S_17' }
  },
  { 'Terrace_S_17': {
    'targetSeat': 'Terrace_S_17' }
  },
  { 'Terrace_S_18': {
    'targetSeat': 'Terrace_S_17' }
  },
  { 'Terrace_S_19': {
    'targetSeat': 'Terrace_S_17' }
  },
  { 'Terrace_S_21': {
    'targetSeat': 'Terrace_S_25' }
  },
  { 'Terrace_S_23': {
    'targetSeat': 'Terrace_S_25' }
  },
  { 'Terrace_S_25': {
    'targetSeat': 'Terrace_S_25' }
  },
  { 'Terrace_S_27': {
    'targetSeat': 'Terrace_S_25' }
  },
  { 'Terrace_S_29': {
    'targetSeat': 'Terrace_S_33' }
  },
  { 'Terrace_S_31': {
    'targetSeat': 'Terrace_S_33' }
  },
  { 'Terrace_S_33': {
    'targetSeat': 'Terrace_S_33' }
  },
  /* Terrace Center */
  { 'Terrace_S_201': {
    'targetSeat': 'Terrace_S_203' }
  },
  { 'Terrace_S_202': {
    'targetSeat': 'Terrace_S_203' }
  },
  { 'Terrace_S_203': {
    'targetSeat': 'Terrace_S_203' }
  },
  { 'Terrace_S_204': {
    'targetSeat': 'Terrace_S_203' }
  },
  { 'Terrace_S_205': {
    'targetSeat': 'Terrace_S_203' }
  },
  { 'Terrace_S_206': {
    'targetSeat': 'Terrace_S_203' }
  },
  { 'Terrace_S_207': {
    'targetSeat': 'Terrace_S_212' }
  },
  { 'Terrace_S_208': {
    'targetSeat': 'Terrace_S_212' }
  },
  { 'Terrace_S_209': {
    'targetSeat': 'Terrace_S_212' }
  },
  { 'Terrace_S_210': {
    'targetSeat': 'Terrace_S_212' }
  },
  { 'Terrace_S_211': {
    'targetSeat': 'Terrace_S_212' }
  },
  { 'Terrace_S_212': {
    'targetSeat': 'Terrace_S_212' }
  },
  { 'Terrace_S_213': {
    'targetSeat': 'Terrace_S_212' }
  },
  { 'Terrace_S_214': {
    'targetSeat': 'Terrace_S_212' }
  },
  { 'Terrace_S_215': {
    'targetSeat': 'Terrace_S_212' }
  },
  { 'Terrace_S_216': {
    'targetSeat': 'Terrace_S_212' }
  },
  { 'Terrace_S_217': {
    'targetSeat': 'Terrace_S_212' }
  },
  { 'Terrace_S_218': {
    'targetSeat': 'Terrace_S_221' }
  },
  { 'Terrace_S_219': {
    'targetSeat': 'Terrace_S_221' }
  },
  { 'Terrace_S_220': {
    'targetSeat': 'Terrace_S_221' }
  },
  { 'Terrace_S_221': {
    'targetSeat': 'Terrace_S_221' }
  },
  { 'Terrace_S_222': {
    'targetSeat': 'Terrace_S_221' }
  },
  { 'Terrace_S_223': {
    'targetSeat': 'Terrace_S_221' }
  },
  { 'Terrace_S_224': {
    'targetSeat': 'Terrace_S_221' }
  },
  { 'Terrace_T_201': {
    'targetSeat': 'Terrace_T_205' }
  },
  { 'Terrace_T_202': {
    'targetSeat': 'Terrace_T_205' }
  },
  { 'Terrace_T_203': {
    'targetSeat': 'Terrace_T_205' }
  },
  { 'Terrace_T_204': {
    'targetSeat': 'Terrace_T_205' }
  },
  { 'Terrace_T_205': {
    'targetSeat': 'Terrace_T_205' }
  },
  { 'Terrace_T_206': {
    'targetSeat': 'Terrace_T_205' }
  },
  { 'Terrace_T_207': {
    'targetSeat': 'Terrace_T_205' }
  },
  { 'Terrace_T_208': {
    'targetSeat': 'Terrace_T_205' }
  },
  { 'Terrace_T_209': {
    'targetSeat': 'Terrace_T_212' }
  },
  { 'Terrace_T_210': {
    'targetSeat': 'Terrace_T_212' }
  },
  { 'Terrace_T_211': {
    'targetSeat': 'Terrace_T_212' }
  },
  { 'Terrace_T_212': {
    'targetSeat': 'Terrace_T_212' }
  },
  { 'Terrace_T_213': {
    'targetSeat': 'Terrace_T_212' }
  },
  { 'Terrace_T_214': {
    'targetSeat': 'Terrace_T_212' }
  },
  { 'Terrace_T_215': {
    'targetSeat': 'Terrace_T_212' }
  },
  { 'Terrace_T_216': {
    'targetSeat': 'Terrace_T_219' }
  },
  { 'Terrace_T_217': {
    'targetSeat': 'Terrace_T_219' }
  },
  { 'Terrace_T_218': {
    'targetSeat': 'Terrace_T_219' }
  },
  { 'Terrace_T_219': {
    'targetSeat': 'Terrace_T_219' }
  },
  { 'Terrace_T_220': {
    'targetSeat': 'Terrace_T_219' }
  },
  { 'Terrace_T_221': {
    'targetSeat': 'Terrace_T_219' }
  },
  { 'Terrace_T_222': {
    'targetSeat': 'Terrace_T_219' }
  },
  { 'Terrace_T_223': {
    'targetSeat': 'Terrace_T_219' }
  },
  { 'Terrace_V_201': {
    'targetSeat': 'Terrace_V_204' }
  },
  { 'Terrace_V_202': {
    'targetSeat': 'Terrace_V_204' }
  },
  { 'Terrace_V_203': {
    'targetSeat': 'Terrace_V_204' }
  },
  { 'Terrace_V_204': {
    'targetSeat': 'Terrace_V_204' }
  },
  { 'Terrace_V_205': {
    'targetSeat': 'Terrace_V_204' }
  },
  { 'Terrace_V_206': {
    'targetSeat': 'Terrace_V_204' }
  },
  { 'Terrace_V_207': {
    'targetSeat': 'Terrace_V_204' }
  },
  { 'Terrace_V_208': {
    'targetSeat': 'Terrace_V_212' }
  },
  { 'Terrace_V_209': {
    'targetSeat': 'Terrace_V_212' }
  },
  { 'Terrace_V_210': {
    'targetSeat': 'Terrace_V_212' }
  },
  { 'Terrace_V_211': {
    'targetSeat': 'Terrace_V_212' }
  },
  { 'Terrace_V_212': {
    'targetSeat': 'Terrace_V_212' }
  },
  { 'Terrace_V_213': {
    'targetSeat': 'Terrace_V_212' }
  },
  { 'Terrace_V_214': {
    'targetSeat': 'Terrace_V_212' }
  },
  { 'Terrace_V_215': {
    'targetSeat': 'Terrace_V_218' }
  },
  { 'Terrace_V_216': {
    'targetSeat': 'Terrace_V_218' }
  },
  { 'Terrace_V_217': {
    'targetSeat': 'Terrace_V_218' }
  },
  { 'Terrace_V_218': {
    'targetSeat': 'Terrace_V_218' }
  },
  { 'Terrace_V_219': {
    'targetSeat': 'Terrace_V_218' }
  },
  { 'Terrace_V_220': {
    'targetSeat': 'Terrace_V_218' }
  },
  { 'Terrace_V_221': {
    'targetSeat': 'Terrace_V_218' }
  },
  { 'Terrace_V_222': {
    'targetSeat': 'Terrace_V_218' }
  },
  { 'Terrace_W_201': {
    'targetSeat': 'Terrace_W_203' }
  },
  { 'Terrace_W_202': {
    'targetSeat': 'Terrace_W_203' }
  },
  { 'Terrace_W_203': {
    'targetSeat': 'Terrace_W_203' }
  },
  { 'Terrace_W_204': {
    'targetSeat': 'Terrace_W_203' }
  },
  { 'Terrace_W_205': {
    'targetSeat': 'Terrace_W_203' }
  },
  { 'Terrace_W_206': {
    'targetSeat': 'Terrace_W_203' }
  },
  { 'Terrace_W_207': {
    'targetSeat': 'Terrace_W_203' }
  },
  { 'Terrace_W_208': {
    'targetSeat': 'Terrace_W_211' }
  },
  { 'Terrace_W_209': {
    'targetSeat': 'Terrace_W_211' }
  },
  { 'Terrace_W_210': {
    'targetSeat': 'Terrace_W_211' }
  },
  { 'Terrace_W_211': {
    'targetSeat': 'Terrace_W_211' }
  },
  { 'Terrace_W_212': {
    'targetSeat': 'Terrace_W_211' }
  },
  { 'Terrace_W_213': {
    'targetSeat': 'Terrace_W_211' }
  },
  { 'Terrace_W_214': {
    'targetSeat': 'Terrace_W_211' }
  },
  { 'Terrace_W_215': {
    'targetSeat': 'Terrace_W_218' }
  },
  { 'Terrace_W_216': {
    'targetSeat': 'Terrace_W_218' }
  },
  { 'Terrace_W_217': {
    'targetSeat': 'Terrace_W_218' }
  },
  { 'Terrace_W_218': {
    'targetSeat': 'Terrace_W_218' }
  },
  { 'Terrace_W_219': {
    'targetSeat': 'Terrace_W_218' }
  },
  { 'Terrace_W_220': {
    'targetSeat': 'Terrace_W_218' }
  },
  { 'Terrace_W_221': {
    'targetSeat': 'Terrace_W_218' }
  },
  { 'Terrace_W_222': {
    'targetSeat': 'Terrace_W_218' }
  },
  { 'Terrace_X_201': {
    'targetSeat': 'Terrace_X_203' }
  },
  { 'Terrace_X_202': {
    'targetSeat': 'Terrace_X_203' }
  },
  { 'Terrace_X_203': {
    'targetSeat': 'Terrace_X_203' }
  },
  { 'Terrace_X_204': {
    'targetSeat': 'Terrace_X_203' }
  },
  { 'Terrace_X_205': {
    'targetSeat': 'Terrace_X_203' }
  },
  { 'Terrace_X_206': {
    'targetSeat': 'Terrace_X_203' }
  },
  { 'Terrace_X_207': {
    'targetSeat': 'Terrace_X_209' }
  },
  { 'Terrace_X_208': {
    'targetSeat': 'Terrace_X_209' }
  },
  { 'Terrace_X_209': {
    'targetSeat': 'Terrace_X_209' }
  },
  { 'Terrace_X_210': {
    'targetSeat': 'Terrace_X_209' }
  },
  { 'Terrace_X_211': {
    'targetSeat': 'Terrace_X_209' }
  },
  { 'Terrace_X_212': {
    'targetSeat': 'Terrace_X_209' }
  },
  /* Boxes section */
  { 'Box_A_1': {
    'targetSeat': 'Box_A_2' }
  },
  { 'Box_A_2': {
    'targetSeat': 'Box_A_2' }
  },
  { 'Box_A_3': {
    'targetSeat': 'Box_A_2' }
  },
  { 'Box_A_4': {
    'targetSeat': 'Box_A_2' }
  },
  { 'Box_A_5': {
    'targetSeat': 'Box_A_2' }
  },
  { 'Box_A_5': {
    'targetSeat': 'Box_A_2' }
  },
  { 'Box_A_6': {
    'targetSeat': 'Box_A_2' }
  },
  { 'Box_A_7': {
    'targetSeat': 'Box_A_2' }
  },
  { 'Box_A_8': {
    'targetSeat': 'Box_A_2' }
  },
  { 'Box_B_1': {
    'targetSeat': 'Box_B_2' }
  },
  { 'Box_B_2': {
    'targetSeat': 'Box_B_2' }
  },
  { 'Box_B_3': {
    'targetSeat': 'Box_B_2' }
  },
  { 'Box_B_4': {
    'targetSeat': 'Box_B_2' }
  },
  { 'Box_B_5': {
    'targetSeat': 'Box_B_2' }
  },
  { 'Box_B_5': {
    'targetSeat': 'Box_B_2' }
  },
  { 'Box_B_6': {
    'targetSeat': 'Box_B_2' }
  },
  { 'Box_B_7': {
    'targetSeat': 'Box_B_2' }
  },
  { 'Box_B_8': {
    'targetSeat': 'Box_B_2' }
  },
  { 'Box_C_1': {
    'targetSeat': 'Box_C_2' }
  },
  { 'Box_C_2': {
    'targetSeat': 'Box_C_2' }
  },
  { 'Box_C_3': {
    'targetSeat': 'Box_C_2' }
  },
  { 'Box_C_4': {
    'targetSeat': 'Box_C_6' }
  },
  { 'Box_C_5': {
    'targetSeat': 'Box_C_7' }
  },
  { 'Box_C_5': {
    'targetSeat': 'Box_C_8' }
  },
  { 'Box_C_6': {
    'targetSeat': 'Box_C_2' }
  },
  { 'Box_C_7': {
    'targetSeat': 'Box_C_2' }
  },
  { 'Box_C_8': {
    'targetSeat': 'Box_C_2' }
  },
  { 'Box_C_9': {
    'targetSeat': 'Box_C_2' }
  },
  { 'Box_C_10': {
    'targetSeat': 'Box_C_2' }
  },
  { 'Box_C_11': {
    'targetSeat': 'Box_C_6' }
  },
  { 'Box_C_12': {
    'targetSeat': 'Box_C_6' }
  },
  { 'Box_C_13': {
    'targetSeat': 'Box_C_6' }
  },
  { 'Box_C_14': {
    'targetSeat': 'Box_C_6' }
  },
  { 'Box_D_1': {
    'targetSeat': 'Box_D_4' }
  },
  { 'Box_D_2': {
    'targetSeat': 'Box_D_4' }
  },
  { 'Box_D_3': {
    'targetSeat': 'Box_D_4' }
  },
  { 'Box_D_4': {
    'targetSeat': 'Box_D_4' }
  },
  { 'Box_D_5': {
    'targetSeat': 'Box_D_4' }
  },
  { 'Box_D_6': {
    'targetSeat': 'Box_D_4' }
  },
  { 'Box_D_7': {
    'targetSeat': 'Box_D_4' }
  },
  { 'Box_D_8': {
    'targetSeat': 'Box_D_4' }
  },
  { 'Box_D_9': {
    'targetSeat': 'Box_D_4' }
  },
  { 'Box_D_10': {
    'targetSeat': 'Box_D_4' }
  },
  { 'Box_D_11': {
    'targetSeat': 'Box_D_4' }
  },
  { 'Box_D_12': {
    'targetSeat': 'Box_D_4' }
  },
  { 'Box_D_13': {
    'targetSeat': 'Box_D_4' }
  },
  { 'Box_D_14': {
    'targetSeat': 'Box_D_4' }
  },
  { 'Box_E_1': {
    'targetSeat': 'Box_E_3' }
  },
  { 'Box_E_2': {
    'targetSeat': 'Box_E_3' }
  },
  { 'Box_E_3': {
    'targetSeat': 'Box_E_3' }
  },
  { 'Box_E_4': {
    'targetSeat': 'Box_E_3' }
  },
  { 'Box_E_5': {
    'targetSeat': 'Box_E_8' }
  },
  { 'Box_E_6': {
    'targetSeat': 'Box_E_8' }
  },
  { 'Box_E_7': {
    'targetSeat': 'Box_E_8' }
  },
  { 'Box_E_8': {
    'targetSeat': 'Box_E_8' }
  },
  { 'Box_E_9': {
    'targetSeat': 'Box_E_3' }
  },
  { 'Box_E_10': {
    'targetSeat': 'Box_E_3' }
  },
  { 'Box_E_11': {
    'targetSeat': 'Box_E_8' }
  },
  { 'Box_E_12': {
    'targetSeat': 'Box_E_8' }
  },
  { 'Box_F_1': {
    'targetSeat': 'Box_F_2' }
  },
  { 'Box_F_2': {
    'targetSeat': 'Box_F_2' }
  },
  { 'Box_F_3': {
    'targetSeat': 'Box_F_2' }
  },
  { 'Box_F_4': {
    'targetSeat': 'Box_F_2' }
  },
  { 'Box_F_5': {
    'targetSeat': 'Box_F_2' }
  },
  { 'Box_F_6': {
    'targetSeat': 'Box_F_2' }
  },
  { 'Box_F_7': {
    'targetSeat': 'Box_F_2' }
  },
  { 'Box_F_8': {
    'targetSeat': 'Box_F_2' }
  },
  /* Orchestra Section */
  { 'Orchestra_A_101': {
    'targetSeat': 'Orchestra_B_103' }
  },
  { 'Orchestra_A_102': {
    'targetSeat': 'Orchestra_B_103' }
  },
  { 'Orchestra_A_103': {
    'targetSeat': 'Orchestra_B_103' }
  },
  { 'Orchestra_A_104': {
    'targetSeat': 'Orchestra_B_103' }
  },
  { 'Orchestra_A_105': {
    'targetSeat': 'Orchestra_B_107' }
  },
  { 'Orchestra_A_106': {
    'targetSeat': 'Orchestra_B_107' }
  },
  { 'Orchestra_A_107': {
    'targetSeat': 'Orchestra_B_107' }
  },
  { 'Orchestra_A_108': {
    'targetSeat': 'Orchestra_B_107' }
  },
  { 'Orchestra_A_109': {
    'targetSeat': 'Orchestra_B_107' }
  },
  { 'Orchestra_A_110': {
    'targetSeat': 'Orchestra_B_112' }
  },
  { 'Orchestra_A_111': {
    'targetSeat': 'Orchestra_B_112' }
  },
  { 'Orchestra_A_112': {
    'targetSeat': 'Orchestra_B_112' }
  },
  { 'Orchestra_B_1': {
    'targetSeat': 'Orchestra_B_3' }
  },
  { 'Orchestra_B_3': {
    'targetSeat': 'Orchestra_B_3' }
  },
  { 'Orchestra_B_5': {
    'targetSeat': 'Orchestra_B_3' }
  },
  { 'Orchestra_B_7': {
    'targetSeat': 'Orchestra_B_3' }
  },
  { 'Orchestra_B_2': {
    'targetSeat': 'Orchestra_B_4' }
  },
  { 'Orchestra_B_4': {
    'targetSeat': 'Orchestra_B_4' }
  },
  { 'Orchestra_B_6': {
    'targetSeat': 'Orchestra_B_4' }
  },
  { 'Orchestra_B_8': {
    'targetSeat': 'Orchestra_B_4' }
  },
  { 'Orchestra_B_101': {
    'targetSeat': 'Orchestra_B_103' }
  },
  { 'Orchestra_B_102': {
    'targetSeat': 'Orchestra_B_103' }
  },
  { 'Orchestra_B_103': {
    'targetSeat': 'Orchestra_B_103' }
  },
  { 'Orchestra_B_104': {
    'targetSeat': 'Orchestra_B_103' }
  },
  { 'Orchestra_B_105': {
    'targetSeat': 'Orchestra_B_107' }
  },
  { 'Orchestra_B_106': {
    'targetSeat': 'Orchestra_B_107' }
  },
  { 'Orchestra_B_107': {
    'targetSeat': 'Orchestra_B_107' }
  },
  { 'Orchestra_B_108': {
    'targetSeat': 'Orchestra_B_107' }
  },
  { 'Orchestra_B_109': {
    'targetSeat': 'Orchestra_B_107' }
  },
  { 'Orchestra_B_110': {
    'targetSeat': 'Orchestra_B_112' }
  },
  { 'Orchestra_B_111': {
    'targetSeat': 'Orchestra_B_112' }
  },
  { 'Orchestra_B_112': {
    'targetSeat': 'Orchestra_B_112' }
  },
  { 'Orchestra_B_113': {
    'targetSeat': 'Orchestra_B_112' }
  },
  { 'Orchestra_C_1': {
    'targetSeat': 'Orchestra_B_3' }
  },
  { 'Orchestra_C_3': {
    'targetSeat': 'Orchestra_B_3' }
  },
  { 'Orchestra_C_5': {
    'targetSeat': 'Orchestra_B_3' }
  },
  { 'Orchestra_C_7': {
    'targetSeat': 'Orchestra_B_3' }
  },
  { 'Orchestra_C_2': {
    'targetSeat': 'Orchestra_D_4' }
  },
  { 'Orchestra_C_4': {
    'targetSeat': 'Orchestra_D_4' }
  },
  { 'Orchestra_C_6': {
    'targetSeat': 'Orchestra_D_4' }
  },
  { 'Orchestra_C_8': {
    'targetSeat': 'Orchestra_D_4' }
  },
  { 'Orchestra_C_101': {
    'targetSeat': 'Orchestra_D_103' }
  },
  { 'Orchestra_C_102': {
    'targetSeat': 'Orchestra_D_103' }
  },
  { 'Orchestra_C_103': {
    'targetSeat': 'Orchestra_D_103' }
  },
  { 'Orchestra_C_104': {
    'targetSeat': 'Orchestra_D_103' }
  },
  { 'Orchestra_C_105': {
    'targetSeat': 'Orchestra_D_108' }
  },
  { 'Orchestra_C_106': {
    'targetSeat': 'Orchestra_D_108' }
  },
  { 'Orchestra_C_107': {
    'targetSeat': 'Orchestra_D_108' }
  },
  { 'Orchestra_C_108': {
    'targetSeat': 'Orchestra_D_108' }
  },
  { 'Orchestra_C_109': {
    'targetSeat': 'Orchestra_D_108' }
  },
  { 'Orchestra_C_110': {
    'targetSeat': 'Orchestra_D_113' }
  },
  { 'Orchestra_C_111': {
    'targetSeat': 'Orchestra_D_113' }
  },
  { 'Orchestra_C_112': {
    'targetSeat': 'Orchestra_D_113' }
  },
  { 'Orchestra_C_113': {
    'targetSeat': 'Orchestra_D_113' }
  },
  { 'Orchestra_C_114': {
    'targetSeat': 'Orchestra_D_113' }
  },
  { 'Orchestra_D_2': {
    'targetSeat': 'Orchestra_D_4' }
  },
  { 'Orchestra_D_4': {
    'targetSeat': 'Orchestra_D_4' }
  },
  { 'Orchestra_D_6': {
    'targetSeat': 'Orchestra_D_4' }
  },
  { 'Orchestra_D_8': {
    'targetSeat': 'Orchestra_D_4' }
  },
  { 'Orchestra_D_101': {
    'targetSeat': 'Orchestra_D_103' }
  },
  { 'Orchestra_D_102': {
    'targetSeat': 'Orchestra_D_103' }
  },
  { 'Orchestra_D_103': {
    'targetSeat': 'Orchestra_D_103' }
  },
  { 'Orchestra_D_104': {
    'targetSeat': 'Orchestra_D_103' }
  },
  { 'Orchestra_D_105': {
    'targetSeat': 'Orchestra_D_108' }
  },
  { 'Orchestra_D_106': {
    'targetSeat': 'Orchestra_D_108' }
  },
  { 'Orchestra_D_107': {
    'targetSeat': 'Orchestra_D_108' }
  },
  { 'Orchestra_D_108': {
    'targetSeat': 'Orchestra_D_108' }
  },
  { 'Orchestra_D_109': {
    'targetSeat': 'Orchestra_D_108' }
  },
  { 'Orchestra_D_110': {
    'targetSeat': 'Orchestra_D_113' }
  },
  { 'Orchestra_D_111': {
    'targetSeat': 'Orchestra_D_113' }
  },
  { 'Orchestra_D_112': {
    'targetSeat': 'Orchestra_D_113' }
  },
  { 'Orchestra_D_113': {
    'targetSeat': 'Orchestra_D_113' }
  },
  { 'Orchestra_D_114': {
    'targetSeat': 'Orchestra_D_113' }
  },
  { 'Orchestra_D_115': {
    'targetSeat': 'Orchestra_D_113' }
  },
  { 'Orchestra_E_1': {
    'targetSeat': 'Orchestra_F_3' }
  },
  { 'Orchestra_E_3': {
    'targetSeat': 'Orchestra_F_3' }
  },
  { 'Orchestra_E_5': {
    'targetSeat': 'Orchestra_F_3' }
  },
  { 'Orchestra_E_7': {
    'targetSeat': 'Orchestra_F_3' }
  },
  { 'Orchestra_E_2': {
    'targetSeat': 'Orchestra_F_4' }
  },
  { 'Orchestra_E_4': {
    'targetSeat': 'Orchestra_F_4' }
  },
  { 'Orchestra_E_6': {
    'targetSeat': 'Orchestra_F_4' }
  },
  { 'Orchestra_E_8': {
    'targetSeat': 'Orchestra_F_4' }
  },
  { 'Orchestra_E_101': {
    'targetSeat': 'Orchestra_F_103' }
  },
  { 'Orchestra_E_102': {
    'targetSeat': 'Orchestra_F_103' }
  },
  { 'Orchestra_E_103': {
    'targetSeat': 'Orchestra_F_103' }
  },
  { 'Orchestra_E_104': {
    'targetSeat': 'Orchestra_F_103' }
  },
  { 'Orchestra_E_105': {
    'targetSeat': 'Orchestra_F_103' }
  },
  { 'Orchestra_E_106': {
    'targetSeat': 'Orchestra_F_108' }
  },
  { 'Orchestra_E_107': {
    'targetSeat': 'Orchestra_F_108' }
  },
  { 'Orchestra_E_108': {
    'targetSeat': 'Orchestra_F_108' }
  },
  { 'Orchestra_E_109': {
    'targetSeat': 'Orchestra_F_108' }
  },
  { 'Orchestra_E_110': {
    'targetSeat': 'Orchestra_F_108' }
  },
  { 'Orchestra_E_111': {
    'targetSeat': 'Orchestra_F_113' }
  },
  { 'Orchestra_E_112': {
    'targetSeat': 'Orchestra_F_113' }
  },
  { 'Orchestra_E_113': {
    'targetSeat': 'Orchestra_F_113' }
  },
  { 'Orchestra_E_114': {
    'targetSeat': 'Orchestra_F_113' }
  },
  { 'Orchestra_F_1': {
    'targetSeat': 'Orchestra_F_3' }
  },
  { 'Orchestra_F_3': {
    'targetSeat': 'Orchestra_F_3' }
  },
  { 'Orchestra_F_5': {
    'targetSeat': 'Orchestra_F_3' }
  },
  { 'Orchestra_F_7': {
    'targetSeat': 'Orchestra_F_3' }
  },
  { 'Orchestra_F_2': {
    'targetSeat': 'Orchestra_F_4' }
  },
  { 'Orchestra_F_4': {
    'targetSeat': 'Orchestra_F_4' }
  },
  { 'Orchestra_F_6': {
    'targetSeat': 'Orchestra_F_4' }
  },
  { 'Orchestra_F_8': {
    'targetSeat': 'Orchestra_F_4' }
  },
  { 'Orchestra_F_101': {
    'targetSeat': 'Orchestra_F_103' }
  },
  { 'Orchestra_F_102': {
    'targetSeat': 'Orchestra_F_103' }
  },
  { 'Orchestra_F_103': {
    'targetSeat': 'Orchestra_F_103' }
  },
  { 'Orchestra_F_104': {
    'targetSeat': 'Orchestra_F_103' }
  },
  { 'Orchestra_F_105': {
    'targetSeat': 'Orchestra_F_103' }
  },
  { 'Orchestra_F_106': {
    'targetSeat': 'Orchestra_F_108' }
  },
  { 'Orchestra_F_107': {
    'targetSeat': 'Orchestra_F_108' }
  },
  { 'Orchestra_F_108': {
    'targetSeat': 'Orchestra_F_108' }
  },
  { 'Orchestra_F_109': {
    'targetSeat': 'Orchestra_F_108' }
  },
  { 'Orchestra_F_110': {
    'targetSeat': 'Orchestra_F_108' }
  },
  { 'Orchestra_F_111': {
    'targetSeat': 'Orchestra_F_113' }
  },
  { 'Orchestra_F_112': {
    'targetSeat': 'Orchestra_F_113' }
  },
  { 'Orchestra_F_113': {
    'targetSeat': 'Orchestra_F_113' }
  },
  { 'Orchestra_F_114': {
    'targetSeat': 'Orchestra_F_113' }
  },
  { 'Orchestra_F_115': {
    'targetSeat': 'Orchestra_F_113' }
  },
  { 'Orchestra_G_1': {
    'targetSeat': 'Orchestra_H_3' }
  },
  { 'Orchestra_G_3': {
    'targetSeat': 'Orchestra_H_3' }
  },
  { 'Orchestra_G_5': {
    'targetSeat': 'Orchestra_H_3' }
  },
  { 'Orchestra_G_7': {
    'targetSeat': 'Orchestra_H_3' }
  },
  { 'Orchestra_G_2': {
    'targetSeat': 'Orchestra_H_4' }
  },
  { 'Orchestra_G_4': {
    'targetSeat': 'Orchestra_H_4' }
  },
  { 'Orchestra_G_6': {
    'targetSeat': 'Orchestra_H_4' }
  },
  { 'Orchestra_G_8': {
    'targetSeat': 'Orchestra_H_4' }
  },
  { 'Orchestra_G_101': {
    'targetSeat': 'Orchestra_H_103' }
  },
  { 'Orchestra_G_102': {
    'targetSeat': 'Orchestra_H_103' }
  },
  { 'Orchestra_G_103': {
    'targetSeat': 'Orchestra_H_103' }
  },
  { 'Orchestra_G_104': {
    'targetSeat': 'Orchestra_H_103' }
  },
  { 'Orchestra_G_105': {
    'targetSeat': 'Orchestra_H_103' }
  },
  { 'Orchestra_G_106': {
    'targetSeat': 'Orchestra_H_108' }
  },
  { 'Orchestra_G_107': {
    'targetSeat': 'Orchestra_H_108' }
  },
  { 'Orchestra_G_108': {
    'targetSeat': 'Orchestra_H_108' }
  },
  { 'Orchestra_G_109': {
    'targetSeat': 'Orchestra_H_108' }
  },
  { 'Orchestra_G_110': {
    'targetSeat': 'Orchestra_H_108' }
  },
  { 'Orchestra_G_111': {
    'targetSeat': 'Orchestra_H_113' }
  },
  { 'Orchestra_G_112': {
    'targetSeat': 'Orchestra_H_113' }
  },
  { 'Orchestra_G_113': {
    'targetSeat': 'Orchestra_H_113' }
  },
  { 'Orchestra_G_114': {
    'targetSeat': 'Orchestra_H_113' }
  },
  { 'Orchestra_H_1': {
    'targetSeat': 'Orchestra_H_3' }
  },
  { 'Orchestra_H_3': {
    'targetSeat': 'Orchestra_H_3' }
  },
  { 'Orchestra_H_5': {
    'targetSeat': 'Orchestra_H_3' }
  },
  { 'Orchestra_H_7': {
    'targetSeat': 'Orchestra_H_3' }
  },
  { 'Orchestra_H_2': {
    'targetSeat': 'Orchestra_H_4' }
  },
  { 'Orchestra_H_4': {
    'targetSeat': 'Orchestra_H_4' }
  },
  { 'Orchestra_H_6': {
    'targetSeat': 'Orchestra_H_4' }
  },
  { 'Orchestra_H_8': {
    'targetSeat': 'Orchestra_H_4' }
  },
  { 'Orchestra_H_101': {
    'targetSeat': 'Orchestra_H_103' }
  },
  { 'Orchestra_H_102': {
    'targetSeat': 'Orchestra_H_103' }
  },
  { 'Orchestra_H_103': {
    'targetSeat': 'Orchestra_H_103' }
  },
  { 'Orchestra_H_104': {
    'targetSeat': 'Orchestra_H_103' }
  },
  { 'Orchestra_H_105': {
    'targetSeat': 'Orchestra_H_103' }
  },
  { 'Orchestra_H_106': {
    'targetSeat': 'Orchestra_H_108' }
  },
  { 'Orchestra_H_107': {
    'targetSeat': 'Orchestra_H_108' }
  },
  { 'Orchestra_H_108': {
    'targetSeat': 'Orchestra_H_108' }
  },
  { 'Orchestra_H_109': {
    'targetSeat': 'Orchestra_H_108' }
  },
  { 'Orchestra_H_110': {
    'targetSeat': 'Orchestra_H_108' }
  },
  { 'Orchestra_H_111': {
    'targetSeat': 'Orchestra_H_113' }
  },
  { 'Orchestra_H_112': {
    'targetSeat': 'Orchestra_H_113' }
  },
  { 'Orchestra_H_113': {
    'targetSeat': 'Orchestra_H_113' }
  },
  { 'Orchestra_H_114': {
    'targetSeat': 'Orchestra_H_113' }
  },
  { 'Orchestra_H_115': {
    'targetSeat': 'Orchestra_H_113' }
  },
  { 'Orchestra_J_2': {
    'targetSeat': 'Orchestra_K_4' }
  },
  { 'Orchestra_J_4': {
    'targetSeat': 'Orchestra_K_4' }
  },
  { 'Orchestra_J_6': {
    'targetSeat': 'Orchestra_K_4' }
  },
  { 'Orchestra_J_8': {
    'targetSeat': 'Orchestra_K_4' }
  },
  { 'Orchestra_J_101': {
    'targetSeat': 'Orchestra_K_103' }
  },
  { 'Orchestra_J_102': {
    'targetSeat': 'Orchestra_K_103' }
  },
  { 'Orchestra_J_103': {
    'targetSeat': 'Orchestra_K_103' }
  },
  { 'Orchestra_J_104': {
    'targetSeat': 'Orchestra_K_103' }
  },
  { 'Orchestra_J_105': {
    'targetSeat': 'Orchestra_K_103' }
  },
  { 'Orchestra_J_106': {
    'targetSeat': 'Orchestra_K_108' }
  },
  { 'Orchestra_J_107': {
    'targetSeat': 'Orchestra_K_108' }
  },
  { 'Orchestra_J_108': {
    'targetSeat': 'Orchestra_K_108' }
  },
  { 'Orchestra_J_109': {
    'targetSeat': 'Orchestra_K_108' }
  },
  { 'Orchestra_J_110': {
    'targetSeat': 'Orchestra_K_108' }
  },
  { 'Orchestra_J_111': {
    'targetSeat': 'Orchestra_K_113' }
  },
  { 'Orchestra_J_112': {
    'targetSeat': 'Orchestra_K_113' }
  },
  { 'Orchestra_J_113': {
    'targetSeat': 'Orchestra_K_113' }
  },
  { 'Orchestra_J_114': {
    'targetSeat': 'Orchestra_K_113' }
  },
  { 'Orchestra_K_1': {
    'targetSeat': 'Orchestra_K_3' }
  },
  { 'Orchestra_K_3': {
    'targetSeat': 'Orchestra_K_3' }
  },
  { 'Orchestra_K_5': {
    'targetSeat': 'Orchestra_K_3' }
  },
  { 'Orchestra_K_7': {
    'targetSeat': 'Orchestra_K_3' }
  },
  { 'Orchestra_K_2': {
    'targetSeat': 'Orchestra_K_4' }
  },
  { 'Orchestra_K_4': {
    'targetSeat': 'Orchestra_K_4' }
  },
  { 'Orchestra_K_6': {
    'targetSeat': 'Orchestra_K_4' }
  },
  { 'Orchestra_K_8': {
    'targetSeat': 'Orchestra_K_4' }
  },
  { 'Orchestra_K_101': {
    'targetSeat': 'Orchestra_K_103' }
  },
  { 'Orchestra_K_102': {
    'targetSeat': 'Orchestra_K_103' }
  },
  { 'Orchestra_K_103': {
    'targetSeat': 'Orchestra_K_103' }
  },
  { 'Orchestra_K_104': {
    'targetSeat': 'Orchestra_K_103' }
  },
  { 'Orchestra_K_105': {
    'targetSeat': 'Orchestra_K_103' }
  },
  { 'Orchestra_K_106': {
    'targetSeat': 'Orchestra_K_108' }
  },
  { 'Orchestra_K_107': {
    'targetSeat': 'Orchestra_K_108' }
  },
  { 'Orchestra_K_108': {
    'targetSeat': 'Orchestra_K_108' }
  },
  { 'Orchestra_K_109': {
    'targetSeat': 'Orchestra_K_108' }
  },
  { 'Orchestra_K_110': {
    'targetSeat': 'Orchestra_K_108' }
  },
  { 'Orchestra_K_111': {
    'targetSeat': 'Orchestra_K_113' }
  },
  { 'Orchestra_K_112': {
    'targetSeat': 'Orchestra_K_113' }
  },
  { 'Orchestra_K_113': {
    'targetSeat': 'Orchestra_K_113' }
  },
  { 'Orchestra_K_114': {
    'targetSeat': 'Orchestra_K_113' }
  },
  { 'Orchestra_K_115': {
    'targetSeat': 'Orchestra_K_113' }
  },
  { 'Orchestra_L_1': {
    'targetSeat': 'Orchestra_K_3' }
  },
  { 'Orchestra_L_3': {
    'targetSeat': 'Orchestra_K_3' }
  },
  { 'Orchestra_L_2': {
    'targetSeat': 'Orchestra_K_4' }
  },
  { 'Orchestra_L_4': {
    'targetSeat': 'Orchestra_K_4' }
  },
  { 'Orchestra_L_101': {
    'targetSeat': 'Orchestra_M_103' }
  },
  { 'Orchestra_L_102': {
    'targetSeat': 'Orchestra_M_103' }
  },
  { 'Orchestra_L_103': {
    'targetSeat': 'Orchestra_M_103' }
  },
  { 'Orchestra_L_104': {
    'targetSeat': 'Orchestra_M_103' }
  },
  { 'Orchestra_L_105': {
    'targetSeat': 'Orchestra_M_103' }
  },
  { 'Orchestra_L_106': {
    'targetSeat': 'Orchestra_M_108' }
  },
  { 'Orchestra_L_107': {
    'targetSeat': 'Orchestra_M_108' }
  },
  { 'Orchestra_L_108': {
    'targetSeat': 'Orchestra_M_108' }
  },
  { 'Orchestra_L_109': {
    'targetSeat': 'Orchestra_M_108' }
  },
  { 'Orchestra_L_110': {
    'targetSeat': 'Orchestra_M_108' }
  },
  { 'Orchestra_L_111': {
    'targetSeat': 'Orchestra_M_113' }
  },
  { 'Orchestra_L_112': {
    'targetSeat': 'Orchestra_M_113' }
  },
  { 'Orchestra_L_113': {
    'targetSeat': 'Orchestra_M_113' }
  },
  { 'Orchestra_L_114': {
    'targetSeat': 'Orchestra_M_113' }
  },
  { 'Orchestra_M_101': {
    'targetSeat': 'Orchestra_M_103' }
  },
  { 'Orchestra_M_102': {
    'targetSeat': 'Orchestra_M_103' }
  },
  { 'Orchestra_M_103': {
    'targetSeat': 'Orchestra_M_103' }
  },
  { 'Orchestra_M_104': {
    'targetSeat': 'Orchestra_M_103' }
  },
  { 'Orchestra_M_105': {
    'targetSeat': 'Orchestra_M_103' }
  },
  { 'Orchestra_M_106': {
    'targetSeat': 'Orchestra_M_108' }
  },
  { 'Orchestra_M_107': {
    'targetSeat': 'Orchestra_M_108' }
  },
  { 'Orchestra_M_108': {
    'targetSeat': 'Orchestra_M_108' }
  },
  { 'Orchestra_M_109': {
    'targetSeat': 'Orchestra_M_108' }
  },
  { 'Orchestra_M_110': {
    'targetSeat': 'Orchestra_M_108' }
  },
  { 'Orchestra_M_111': {
    'targetSeat': 'Orchestra_M_113' }
  },
  { 'Orchestra_M_112': {
    'targetSeat': 'Orchestra_M_113' }
  },
  { 'Orchestra_M_113': {
    'targetSeat': 'Orchestra_M_113' }
  },
  { 'Orchestra_M_114': {
    'targetSeat': 'Orchestra_M_113' }
  },
  { 'Orchestra_M_115': {
    'targetSeat': 'Orchestra_M_113' }
  },
  { 'Orchestra_N_101': {
    'targetSeat': 'Orchestra_O_103' }
  },
  { 'Orchestra_N_102': {
    'targetSeat': 'Orchestra_O_103' }
  },
  { 'Orchestra_N_103': {
    'targetSeat': 'Orchestra_O_103' }
  },
  { 'Orchestra_N_104': {
    'targetSeat': 'Orchestra_O_103' }
  },
  { 'Orchestra_N_105': {
    'targetSeat': 'Orchestra_O_103' }
  },
  { 'Orchestra_N_106': {
    'targetSeat': 'Orchestra_O_108' }
  },
  { 'Orchestra_N_107': {
    'targetSeat': 'Orchestra_O_108' }
  },
  { 'Orchestra_N_108': {
    'targetSeat': 'Orchestra_O_108' }
  },
  { 'Orchestra_N_109': {
    'targetSeat': 'Orchestra_O_108' }
  },
  { 'Orchestra_N_110': {
    'targetSeat': 'Orchestra_O_108' }
  },
  { 'Orchestra_N_111': {
    'targetSeat': 'Orchestra_O_112' }
  },
  { 'Orchestra_N_112': {
    'targetSeat': 'Orchestra_O_112' }
  },
  { 'Orchestra_O_101': {
    'targetSeat': 'Orchestra_O_103' }
  },
  { 'Orchestra_O_102': {
    'targetSeat': 'Orchestra_O_103' }
  },
  { 'Orchestra_O_103': {
    'targetSeat': 'Orchestra_O_103' }
  },
  { 'Orchestra_O_104': {
    'targetSeat': 'Orchestra_O_103' }
  },
  { 'Orchestra_O_105': {
    'targetSeat': 'Orchestra_O_103' }
  },
  { 'Orchestra_O_106': {
    'targetSeat': 'Orchestra_O_108' }
  },
  { 'Orchestra_O_107': {
    'targetSeat': 'Orchestra_O_108' }
  },
  { 'Orchestra_O_108': {
    'targetSeat': 'Orchestra_O_108' }
  },
  { 'Orchestra_O_109': {
    'targetSeat': 'Orchestra_O_108' }
  },
  { 'Orchestra_O_110': {
    'targetSeat': 'Orchestra_O_108' }
  },
  { 'Orchestra_O_111': {
    'targetSeat': 'Orchestra_O_112' }
  },
  { 'Orchestra_O_112': {
    'targetSeat': 'Orchestra_O_112' }
  },
  { 'Orchestra_O_113': {
    'targetSeat': 'Orchestra_O_112' }
  },
  { 'Orchestra_O_114': {
    'targetSeat': 'Orchestra_O_112' }
  },
  { 'Orchestra_O_115': {
    'targetSeat': 'Orchestra_O_112' }
  },
  { 'Orchestra_P_101': {
    'targetSeat': 'Orchestra_O_103' }
  },
  { 'Orchestra_P_102': {
    'targetSeat': 'Orchestra_O_103' }
  },
  { 'Orchestra_P_103': {
    'targetSeat': 'Orchestra_O_103' }
  },
  { 'Orchestra_P_104': {
    'targetSeat': 'Orchestra_O_103' }
  },
  { 'Orchestra_P_105': {
    'targetSeat': 'Orchestra_O_103' }
  },
  { 'Orchestra_P_106': {
    'targetSeat': 'Orchestra_O_108' }
  },
  { 'Orchestra_P_107': {
    'targetSeat': 'Orchestra_O_108' }
  },
  { 'Orchestra_P_108': {
    'targetSeat': 'Orchestra_O_108' }
  },
  { 'Orchestra_P_109': {
    'targetSeat': 'Orchestra_O_108' }
  },
  { 'Orchestra_P_110': {
    'targetSeat': 'Orchestra_O_108' }
  },
  { 'Orchestra_P_111': {
    'targetSeat': 'Orchestra_O_112' }
  },
  { 'Orchestra_P_112': {
    'targetSeat': 'Orchestra_O_112' }
  },
  { 'Orchestra_P_113': {
    'targetSeat': 'Orchestra_O_112' }
  },
  { 'Orchestra_P_114': {
    'targetSeat': 'Orchestra_O_112' }
  },
  { 'Orchestra_P_115': {
    'targetSeat': 'Orchestra_O_112' }
  },
  { 'Orchestra_P_116': {
    'targetSeat': 'Orchestra_O_112' }
  }
]
/* eslint-enable no-unused-vars */

export { seatMapObject }
