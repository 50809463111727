require('./fresco')

const assetUrl = 'https://la-jolla-music-society-assets.s3.us-east-1.amazonaws.com/tnew-template/qa/img/'

// If Event Location is Baker Baum, add image of venue to ticket selection screen
if ($(".tn-event-detail__location:contains('The Baker-Baum Concert Hall')").length > 0) {
  $('body').addClass('state--venue-baker-baum')
  const panoImage = document.createElement('div')
  panoImage.classList.add('js__venue-image')
  const panoImageTarget = document.querySelector('.tn-event-detail__location')
  panoImageTarget.insertAdjacentElement('beforeend', panoImage)
  panoImage.innerHTML =
  `<a href="${assetUrl}BB_overhead-seatmap.jpg"
   class="fresco o-link"
   data-fresco-caption="Panoramic view of Baker Baum Concert Hall">Panorama View<i class="far fa-angle-right"></i></a>`
}
// If Event Location is the JAI, add image of venue to ticket selection screen
if ($(".tn-event-detail__location:contains('The JAI')").length > 0) {
  $('body').addClass('state--venue-jai')
  const panoImage = document.createElement('div')
  panoImage.classList.add('js__venue-image')
  const panoImageTarget = document.querySelector('.tn-event-detail__location')
  panoImageTarget.insertAdjacentElement('beforeend', panoImage)
  panoImage.innerHTML =
  `<a href="${assetUrl}JAI_overhead-seatmap.jpg"
   class="fresco o-link"
   data-fresco-caption="Panoramic view of the JAI">Panorama View<i class="far fa-angle-right"></i></a>`
}
